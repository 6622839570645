@font-face {
  font-family: "freemono";
  src: url("../fonts/freemono.ttf") format("truetype");
}
@media (max-width: 480px) {
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
  }
  /* .logo {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  } */
  .menu {
    display: flex;
    justify-content: space-between;
    margin: none;
  }
  li {
    margin: none;
  }
  .video {
    width: 100%;
    height: 50vh;
  }
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media (min-width: 481px) {
  header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    position: sticky;
  }
  .container {
    font-size: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .video {
    width: 100%;
    height: 100vh;
  }
}
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* .logo {
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.logo-text {
  font-family: "freemono", "Courier New", Courier, monospace;
  font-size: 30px;
} */
.menu {
  align-items: center;
}
/* ul {
  display: flex;
}
li {
  display: inline;
  margin-right: 25px;
  text-decoration: none;
}
li a {
  text-decoration: none;
  color: #cccccc;
}
li a:hover {
  color: #5e0066;
} */
.titulo {
  font-family: freemono;
  text-align: center;
  font-size: 30px;
  color: #fff;
  margin: 30px auto 30px auto;
}
.post-frame {
  padding: 0;
  margin: 0;
}
.post {
  padding: 0;
  margin: 0;
  width: 100%;
}
.photo-frame {
  padding: 10px;
}
.photo {
  width: 100%;
}
footer p {
  margin: 50px auto 50px auto;
  font-family: freemono;
  text-align: center;
  color: #cccccc;
}
#arriba {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #cccccc;
  color: black;
  cursor: pointer;
  padding: 15px;
  border-radius: 7px;
}

#arriba:hover {
  background-color: rgb(220, 37, 250);
}
