@import './global'
.fuerzas
    background-color: $bg-white
    padding: 10rem 2rem
    font-family: 'Stranger Things', cursive
    font-size: 1.6rem
    line-break: loose
    img
        width: 5rem
        height: auto
        display: block
        margin: 0 auto
        margin-bottom: 5rem
    p
        margin-bottom: 2rem
        text-align: center
        &:last-child
            margin-top: 5rem
