@import './global'
.portadaep
    display: flex
    flex-direction: column
    height: 100%
    max-height: 100vh
    display: flex
    justify-content: center
    align-items: center
    background-color: $bg-white
    padding: 5rem
    img
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px

    a
        font-size: 2rem
        font-weight: 500
        color: $color-primary
        margin-top: 1.5rem
        text-align: center
        font-family: 'Stranger Things'
        text-decoration: none
