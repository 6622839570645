@import './global.sass'
.nohaylugar
    display: flex
    justify-content: center
    background-color: $bg-white
    position: relative
    overflow: hidden
    padding: 8rem
    gap: 3rem
    &__figures
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        z-index: 1
        .resorte
            position: absolute
            bottom: 3rem
            left: 0
            width: 80px
            z-index: 1
        .rayas
            position: absolute
            top: 3rem
            right: 3rem
            width: 80px
            z-index: 1
        .scratch
            position: absolute
            left: calc( 50% - 35rem )
            top: 15rem
            width: 50px
            height: 50px
            z-index: 1
    &__text

        align-self: center
        width: 40%
        max-width: 30rem
        z-index: 3
        h2
            font-family: 'Spicy Rice', cursive
            font-size: 3rem
            color: black
            text-align: center
            margin-bottom: 2rem
    &__description
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        img
            position: relative
            width: 100%
            max-width: 370px
        p
            font-family: 'Stranger Things', cursive
            font-size: 1.5rem
            color: black
            text-align: center
            position: absolute
            bottom: 2rem
            z-index: 5

    &__image
        display: flex
        justify-content: center
        position: relative
        top: 0
        left: 0
        img
            width: 100%
            max-width: 280px
            height: 100%
            object-fit: cover
            object-position: center

        .cassette
            position: absolute
            top: 5rem
            right: -20rem
            width: 300px
            height: 303px
            ransform-origin: top left
            transform: rotate(180deg) translate(0, -100%) scale(0.5)
            line-height: 285px
            z-index: 2

@media (max-width: 768px)
    .nohaylugar
        flex-direction: column
        padding: 2rem
        gap: 4rem
        padding: 4rem 0
        &__figures
            .scratch
                top: 13rem
                left: calc( 50% - 12rem )
                line-height: 50px
                font-size: 2rem
                transform: rotate(180deg) translate(0, -100%) scale(0.5)
                z-index: 2
        &__text
            width: 100%
            h2
                font-size: 2rem
        &__description
            img
                max-width: 300px
            p
                font-size: 1.5rem
        &__image
            img
                max-width: 200px
            .cassette
                width: 200px
                height: 203px
                transform: rotate(180deg) translate(0, -100%) scale(0.5)
                line-height: 195px
                right: calc( 50% - 25rem )
                top: 5rem
                left: auto
                transform-origin: top left
